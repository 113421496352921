import { render, staticRenderFns } from "./ComponentePD_Ai.vue?vue&type=template&id=7df567aa&scoped=true&"
import script from "./ComponentePD_Ai.vue?vue&type=script&lang=js&"
export * from "./ComponentePD_Ai.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df567aa",
  null
  
)

export default component.exports